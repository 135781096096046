<template>
  <section>
    <vue-headful :title="title" />
    <v-container>
      <!-- dominos en solicitud de aprobacion  -->
      <template v-if="diminiosSolicitudAprobacion.length > 0">
        <v-row no-gutters>
        <v-col cols="12">
          <h1 class="fontD mt-5 pa-3 text-left">{{ subtitle7}}</h1>
        </v-col>
      </v-row>
        <v-row>
          <v-col  v-for="(item, i) in diminiosSolicitudAprobacion"
                 :key="(i)"  cols="12" lg="4" md="12" >
            <v-hover v-slot="{ hover }">
              <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  tile
                  class="mx-auto"
                  disabled
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle class="cardt" style="background-color: #f5f5f5">{{ item.dominio }}</v-card-subtitle>
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                     Solicitud se encuentra en revisión
                    </p>
                    <h3 class="dias" color="cardPendiente">24 horas</h3>
                    <v-progress-linear
                    v-model="item.availablePercent"
                        color="cardPendiente"
                        height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip color="#E8840F" small class="ml-2  white--text ma-2">
                          <v-icon>mdi-clock</v-icon>
                          {{item.estado}}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="pag-dominios-solicitud-aprobacion">
            <v-pagination
                v-model="paginaSolicitudAprobacion"
                :length="paginaSolicitudAprobacionTP"
                :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </template>
      <!-- diminios solicitud aprobados -->
      <template v-if="dominiosSolicitudAprobados.length > 0">
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">{{ subtitle8 }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <!-- aprobados -->
          <v-col
            v-for="(item, i) in dominiosSolicitudAprobados"
            :key="i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Solicitud se encuentra aprobada
                    </p>
                    <h3 class="dias" color="cardPendiente">24 horas</h3>
                    <v-progress-linear
                      v-model="item.availablePercent"
                      color="cardPendiente"
                      height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="cardAdtivegreen"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                  <v-layout class="justify-end">
                    <div class="">
                      <v-btn class="" text @click="SolicitudAprobados()">
                        Registrar
                      </v-btn>
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
          <!-- pendiente aprobacion -->
          <!-- <v-col
            v-for="(item, i) in diminiosSolicitudAprobacion"
            :key="'A' + i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
                disabled
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Solicitud se encuentra en revisión
                    </p>
                    <h3 class="dias" color="cardPendiente">24 horas</h3>
                    <v-progress-linear
                      v-model="item.availablePercent"
                      color="cardPendiente"
                      height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="#E8840F"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col> -->
        </v-row>
        <template>
          <div
            class="text-center justify-center"
            id="pag-dominios-solicitud-aprobados"
          >
            <v-pagination
              v-model="paginaSolcitudAprobado"
              :length="paginaSolcitudAprobadoTP"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </template>
      <!-- fin  -->
      <!-- incio dominios pendientes de informacion -->
      <template v-if="dominiosPendientes.length > 0">
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="fontD mt-5 pa-3 text-left">{{ subtitle4 }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in dominiosPendientes"
            :key="i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Su dominio estará disponible por
                    </p>
                    <h3 class="dias" color="cardPendiente">
                      {{ availableDomainDays(item.fechaVencimiento) }} dias
                    </h3>
                    <v-progress-linear
                      v-model="item.availablePercent"
                      color="cardPendiente"
                      height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="cardPendiente"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                  <v-layout class="justify-end">
                    <div class="">
                      <v-btn
                        v-on:click="toDetalles(item.dominio)"
                        class=""
                        text
                        :to="{
                          name: 'admin-detalledominio',
                          params: { id: item.idDetallePedido },
                        }"
                      >
                        <span
                          class="botonNegro"
                          v-on:click="toDetalles(item.dominio)"
                          >Administrar</span
                        >
                      </v-btn>
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="pag-domios-pendientes">
            <v-pagination
              v-model="paginaPendientes"
              :length="paginado.totaldePaginas"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </template>
      <!-- fin dominios pendientes de informacion -->
      <!-- **********************************************************************
      *************************************************************************** -->
      <!-- Inicio dominios en solicitud de activacion  -->
      <template v-if="dominiosSolicitudActivacion.length > 0">
        <v-row no-gutters>
          <v-col class="mt-5" cols="12">
            <h1 class="fontD mt-3 text-left">{{ subtitle5 }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in dominiosSolicitudActivacion"
            :key="i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Su dominio estará disponible por
                    </p>
                    <h3 class="dias4" color="cardPendienteActivacion">
                      {{ availableDomainDays(item.fechaVencimiento) }} dias
                    </h3>
                    <v-progress-linear
                      v-model="item.availablePercent"
                      color="cardPendienteActivacion"
                      height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="cardPendienteActivacion"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <template>
          <div
            class="text-center justify-center"
            id="pag-domios-solicitud-activacion"
          >
            <v-pagination
              v-model="paginaSolicitudActivacion"
              :length="paginaSolicitudActivacionTP"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </template>
      <!-- fin dominio en solicitud de activacion -->
      <!-- **********************************************************************
      *************************************************************************** -->
      <!-- Dominios activos -->
      <template v-if="dominiosActivos.length > 0">
        <v-row>
          <v-col cols="12">
            <h1 class="fontD mt-3 text-left">{{ title }}</h1>
            <p class="messageDominio">{{ messageDominio }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in dominiosActivos"
            :key="i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Su dominio estará disponible por
                    </p>
                    <template v-if="item.availableDays <= 30">
                      <h3 class="dias" color="cardActiveorange">
                        {{ item.availableDays }} dias
                      </h3>
                    </template>
                    <template v-else>
                      <h3 class="dias2" color="cardActiveorange">
                        {{ item.availableDays }} dias
                      </h3>
                    </template>
                    <template v-if="item.availableDays <= 30">
                      <v-progress-linear
                        v-model="item.availableDays"
                        color="cardActiveorange"
                        height="2"
                      ></v-progress-linear>
                    </template>
                    <template v-else>
                      <v-progress-linear
                        v-model="item.availableDays"
                        color="cardAdtivegreen"
                        height="2"
                      ></v-progress-linear>
                    </template>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="cardAdtivegreen"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                  <v-layout class="justify-end">
                    <div class="">
                      <router-link
                        :to="{
                          name: 'admin-editardominio',
                          params: {
                            id: item.idClienteDominio,
                            dominio: item.dominio,
                            dias: availableDomainDays(item.fechaVencimiento),
                            dominioNombre: nombreDominio(item.dominio, item.idClienteDominio),
                          },
                        }"
                        ><span class="botonNegro"
                          >Administrar</span
                        ></router-link
                      >
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="pag-dominios-activos">
            <v-pagination
              v-model="paginaActivos"
              :length="paginaActivosTP"
              :total-visible="10"
              @click="scrollTo()"
            >
            </v-pagination>
            <v-overlay :value="overlay2">
              <v-progress-circular
                indeterminate
                size="75"
                color="#007BFF"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </template>
      </template>
      <!-- fin dominios octivo -->
      <!-- **********************************************************************
      *************************************************************************** -->
      <!-- dominios suspendidos -->
      <template v-if="dominiosSuspendidos.length > 0">
        <v-row>
          <v-col cols="12">
            <h1 class="fontD mt-3 text-left">{{ subtitle2 }}</h1>
            <p class="messageDominio">{{ messageDominioSuspendido }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, i) in dominiosSuspendidos"
            :key="i"
            cols="12"
            lg="4"
            md="12"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                tile
                class="mx-auto"
              >
                <div>
                  <v-col class="py-0 px-0" cols="12" lg="12">
                    <v-card-subtitle
                      class="cardt"
                      style="background-color: #f5f5f5"
                      >{{ item.dominio }}</v-card-subtitle
                    >
                  </v-col>
                </div>
                <v-row>
                  <v-col cols="12" lg="12" class="px-8">
                    <p class="texto text--primary">
                      Su dominio estará disponible por
                    </p>
                    <h3 class="dias3" color="cardActivedanger">
                      {{ item.availableDays }} dias
                    </h3>
                    <v-progress-linear
                      v-model="item.availableDays"
                      color="cardActivedanger"
                      height="2"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
                <v-container class="d-flex align-center">
                  <v-layout class="justify-start">
                    <div class="py-0 px-0  ">
                      <v-chip-group column>
                        <v-chip
                          color="cardActivedanger"
                          small
                          class="ml-2  white--text ma-2"
                        >
                          <v-icon>mdi-clock</v-icon>
                          {{ item.estado }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-layout>
                  <v-layout class="justify-end">
                    <div class="">
                      <v-btn
                        v-on:click="toDetalles(item.dominio)"
                        class=""
                        text
                        :to="{
                          name: 'admin-detalledominio',
                          params: { id: item.idDetallePedido },
                        }"
                      >
                        <span
                          class="botonNegro"
                          v-on:click="toDetalles(item.dominio)"
                          >Administrar</span
                        >
                      </v-btn>
                      <!-- <router-link :to="{ name: 'admin-editardominio', params: { id: item.idClienteDominio, dominio: item.dominio , dias: availableDomainDays(item.fechaVencimiento)}}"><span class="botonNegro">Administrar</span></router-link> -->
                    </div>
                  </v-layout>
                </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <template>
          <div class="text-center justify-center" id="pag-dominios-suspendidos">
            <v-pagination
              v-model="paginaSuspendidos"
              :length="paginaSuspendidosTP"
              :total-visible="10"
            ></v-pagination>
          </div>
        </template>
      </template>
      <!-- fin dominios suspendidos -->
      <!-- **********************************************************************
      *************************************************************************** -->
      <!-- dominios suspendidos con opcion para comprar de nuevo -->
      <v-template
        v-if="
          historico.filter((student) => isExpired(student.dominio)).length > 0
        "
      >
        <v-row>
          <v-col cols="12">
            <h1 class="fontD mt-3 text-left">{{ subtitle3 }}</h1>
            <p class="messageDominio">{{ messageDominioSuspendido }}</p>
          </v-col>
        </v-row>
        <v-row v-for="(item, i) in historico" :key="i">
          <template v-if="isExpired(item.dominio)">
            <v-col cols="12" lg="4" md="12" class="py-0">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="text-precio2" v-text="icono"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-precio2"
                    v-text="item.dominio"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" lg="4" class="py-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-precio2"
                    >{{ getEstadoDominio(item.fechaVencimiento) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" lg="4" md="12" class="py-0">
              <v-btn class="ma-2 mt-2" text color="green darken-2">
                Re Adquirir
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-template>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="75"
          color="#007BFF"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </section>
</template>
<script>
import Vue from "vue";
import vueHeadful from "vue-headful";
import "vue-toast-notification/dist/theme-sugar.css";
export default {
  components: {
    vueHeadful,
  },
  data: () => ({
    overlay2: false,
    overlay: false,
    page: 1,
    comprarLabel: "Registrar",
    paginaPendientes: 1,
    paginaSolicitudActivacion: 1,
    paginaSolicitudActivacionTP: 1,
    paginaSolicitudAprobacion: 1,
    paginaSolicitudAprobacionTP: 1,
    paginaSolcitudAprobado: 1,
    paginaSolcitudAprobadoTP: 1,
    paginaActivos: 1,
    paginaActivosTP: 1,
    paginaSuspendidos: 1,
    paginaSuspendidosTP: 1,
    paginado: {},
    title: "Dominios Activos",
    subtitle7: "Dominios en solicitud de aprobación",
    subtitle8: "Dominios solicitud aprobada",
    subtitle2: "Dominios suspendidos",
    subtitle4: "Dominios pendientes de Información",
    subtitle5: "Dominios en solicitud de activacion",
    subtitle3: "Historial de dominios adquiridos",
    messageDominio:
      "Tienes 1 dominio próximo a expirar, no lo pierdas, ¡Renuévalo!",
    messageDominioSuspendido:
      "Estos dominios  pueden ser reactivados en un periodo máximo de 30 días posterior a su suspensión",
    messageDominioAdquiridos:
      "Estos son los dominios que has adquirido con nosotros, algunos quizás puedan ser readquiridos",
    icono: "mdi-network",
    items: [
      {
        text: "dominio.tv.ni",
        exp: "expirado hace un año",
        icon: "mdi-network",
      },
      {
        text: "dominio.net.ni",
        exp: "expirado hace un año",
        icon: "mdi-network",
      },
      { text: "dominio.com.ni", icon: "mdi-network" },
    ],
    dominios: [],

    valueDeterminate: 50,
    historico: [],
    diminiosSolicitudAprobacion: [],
    dominiosSolicitudAprobados: [],
    dominiosPendientes: [],
    dominiosActivos: [],
    dominiosSuspendidos: [],
    dominiosSolicitudActivacion: [],
  }),
  watch: {
    paginaSolicitudAprobacion: function() {
      this.getSolicitudAprobacion();
    },
    paginaSolcitudAprobado: function() {
      this.getDominiosAprobados();
    },
    paginaPendientes: function() {
      this.getDominiosPendientes();
    },
    paginaSolicitudActivacion: function() {
      this.getDominiosSolicitudActivacion();
    },
    paginaActivos: function() {
      this.getDominiosActivos();
    },
    paginaSuspendidos: function() {
      this.getDominiosSuspendidos();
    },
    overlay2(val) {
      val &&
        setTimeout(() => {
          this.overlay2 = false;
        }, 6130);
    },
  },
  mounted() {
    this.param = this.$route.params.id;
    localStorage.removeItem("codigoReferencia");
    localStorage.removeItem("codigoResultado");
    this.getSolicitudAprobacion();
    this.getDominiosAprobados();
    this.getDominiosPendientes();
    this.overlay = true;
    this.getDominiosSolicitudActivacion();
    this.getDominiosActivos();
    this.getDominiosSuspendidos();
  },
  methods: {
    toasterMessageResponse: function(msg, type) {
      Vue.$toast.open({
        message: msg,
        type: type,
      });
    },

    toDetalles: function(dominio) {
      localStorage.removeItem("detalledominio");
      localStorage.setItem("detalledominio", dominio);
    },
    getEstadoDominio: function(fechaVencimiento) {
      var current = new Date();
      var dt1 = new Date(fechaVencimiento);
      let result = Math.floor(
        (Date.UTC(
          current.getFullYear(),
          current.getMonth(),
          current.getDate()
        ) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      return result < 0
        ? result * -1 + " dias restantes "
        : "vencido hace " + result * -1 + " dias";
    },
    availableDomainDays: function(fechaVencimiento) {
      var current = new Date();
      var dt1 = new Date(fechaVencimiento);
      let result = Math.floor(
        (Date.UTC(
          current.getFullYear(),
          current.getMonth(),
          current.getDate()
        ) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      return result * -1;
    },
    nombreDominio(dominio, idClienDom){
      let dominios = dominio;
      localStorage.setItem("nombreDominio", dominios);
      localStorage.setItem("idCienteDom", idClienDom)
    },
    availablePercentDays: function(fechaVencimiento) {
      var current = new Date();
      var dt1 = new Date(fechaVencimiento);
      let result = Math.floor(
        (Date.UTC(
          current.getFullYear(),
          current.getMonth(),
          current.getDate()
        ) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      result = result * -1;
      if (result < 0) return 0;
      return (100 * result) / 365;
    },
    isExpired: function(fechaVencimiento) {
      var current = new Date();
      var dt1 = new Date(fechaVencimiento);
      let result = Math.floor(
        (Date.UTC(
          current.getFullYear(),
          current.getMonth(),
          current.getDate()
        ) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
      if (result < 0) return true;
      else return false;
    },
    SolicitudAprobados: async function() {
      await this.$http
        .get(this.$keys("SOLICITUD_APROBACION_DOMINIO"))
        .then((response) => {
          if (response.status === 200) {
            for (
              let indexAlmacen = 0;
              indexAlmacen < response.data.length;
              indexAlmacen++
            ) {
              const element = response.data[indexAlmacen];
              this.dominios.push({
                dominio: element.dominio,
                dominioFullName: element.dominioFullName,
                idNivel: element.idNivel,
                moneda: element.moneda,
                idSolicitudAprobacionDominioDetalle:
                  element.idSolicitudAprobacionDominioDetalle,
                idZona: element.idZona,
                precioReal: element.precioReal,
                idDescuento: 0,
                montoDescuento: 0,
              });
            }
            console.log("Dominios: ", this.dominios);
            localStorage.setItem("localdomain", JSON.stringify(this.dominios));
            console.log("Set: ", this.dominios);
            location.href = "/compradominio";
          }
        });
    },
    getDominiosAprobados: async function() {
      this.overlay2 = true;
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Solicitud Aprobada&pagina=" +
            this.paginaSolcitudAprobado +
            "&registroPorPagina=30"
        )
        .then((response) => {
          if (response.status === 200) {
            // this.overlay2 = true
            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));
              this.dominiosSolicitudAprobados = this.historico.filter(
                (x) => x.idEstado === 0
              );
              this.paginaSolcitudAprobadoTP =
                response.data.paginado.totaldePaginas;
            }
            //  this.overlay2 = true
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    getSolicitudAprobacion: async function() {
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Solicitud Aprobacion&pagina=" +
            this.paginaSolicitudAprobacion +
            "&registroPorPagina=6"
        )
        .then((response) => {
          if (response.status === 200) {
            this.overlay = false;

            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));
              this.diminiosSolicitudAprobacion = this.historico.filter(
                (x) => x.idEstado === 0
              );
              this.paginaSolicitudAprobacionTP =
                response.data.paginado.totaldePaginas;
            }
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    getDominiosPendientes: async function() {
      this.overlay2 = true;
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Pendiente&pagina=" +
            this.paginaPendientes +
            "&registroPorPagina=6"
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));
              this.dominiosPendientes = this.historico.filter(
                (x) => x.idEstado === 0
              );
              this.paginado = response.data.paginado;
            }
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    getDominiosSolicitudActivacion: async function() {
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Solicitud Activación&pagina=" +
            this.paginaSolicitudActivacion +
            "&registroPorPagina=6"
        )
        .then((response) => {
          if (response.status === 200) {
            this.overlay = false;

            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));
              this.dominiosSolicitudActivacion = this.historico.filter(
                (x) => x.idEstado === 2
              );
              this.paginaSolicitudActivacionTP =
                response.data.paginado.totaldePaginas;
            }
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    getDominiosActivos: async function() {
      this.overlay2 = true;
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Activo&pagina=" +
            this.paginaActivos +
            "&registroPorPagina=6"
        )
        .then((response) => {
          if (response.status === 200) {
            // this.overlay2 = true
            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));
              this.dominiosActivos = this.historico.filter(
                (x) => x.idEstado === 3
              );
              this.paginaActivosTP = response.data.paginado.totaldePaginas;
            }
            //  this.overlay2 = true
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    getDominiosSuspendidos: async function() {
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .get(
          this.$keys("DOMINIO_HISTORICO") +
            "?Estado=Suspendido&pagina=" +
            this.paginaSuspendidos +
            "&registroPorPagina=6"
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.tieneItems === true) {
              this.historico = response.data.items.map((obj) => ({
                ...obj,
                availablePercent: this.availablePercentDays(
                  obj.fechaVencimiento
                ),
                availableDays: this.availableDomainDays(obj.fechaVencimiento),
              }));

              this.dominiosSuspendidos = this.historico.filter(
                (x) => x.idEstado === 4
              );
              this.paginaSuspendidosTP = response.data.paginado.totaldePaginas;
            }
          }
        })
        .catch((error) => {
          let mensajeerror;
          // tslint:disable-next-line:forin
          if (error.response.data?.errors) {
            for (var key in error.response.data.errors) {
              var value = error.response.data.errors[key];
              // console.log(value);

              value.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback = JSON.parse(error.response.data?.mensaje);
            for (var key2 in erroresback.errors) {
              var value2 = erroresback.errors[key2];

              value2.forEach((msg) => {
                if (mensajeerror) {
                  mensajeerror = mensajeerror + "<br>" + msg;
                } else {
                  mensajeerror = msg;
                }
              });
            }
          }

          if (error.response.data?.mensaje) {
            let erroresback2 = JSON.parse(error.response.data?.mensaje);

            if (mensajeerror) {
              mensajeerror = mensajeerror + "<br>" + erroresback2?.mensaje;
            } else {
              mensajeerror = erroresback2?.mensaje;
            }
          }

          this.toasterMessageResponse(mensajeerror, "error");
          this.overlay2 = true;
        });
    },
    scrollTop() {
      setTimeout(() => {
        window.scrollTo({
          top: 380,
        });
      }, 200);
    },
  },
};
</script>
<style>
.card:not(.on-hover) {
  opacity: 0.2;
}

.card {
  transition: opacity 3s ease-in-out;
}

.fontD {
  color: #616161 !important;
  font-weight: 600;
  font-size: 36px !important;
}
@media (max-width: 725px) {
  .fontD {
    font-size: 22px !important;
  }
}
.messageDominio {
  color: #616161 !important;
}

.cardt {
  font-size: 20px;
  color: #4f4f4f !important;
}

.texto {
  text-align: center;
}

.dias {
  font-size: 30px;
  text-align: center;
  /*color: #F57C00;*/
  color: #424242;
  /* border-bottom: #F57C00 2px solid; */
}

.dias1 {
  font-size: 30px;
  text-align: center;
  color: #424242;
  /* border-bottom: #F57C00 2px solid; */
}

.cardPanel {
  background-color: #fafafa !important;
}

.dias2 {
  font-size: 30px;
  text-align: center;
  color: #43a047;
}
.dias4 {
  font-size: 30px;
  text-align: center;
  color: #a5d6a7;
}

.dias3 {
  font-size: 30px;
  text-align: center;
  color: #d32f2f;
}

.text-precio2 {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 58px !important;
  color: #abaaaa !important;
}

.botonNegro {
  color: #000;
  text-decoration: none;
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff;
  background-color: #424242 !important;
}
/* spinner */
</style>
